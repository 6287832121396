import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { CurrentUserService } from './current-user.service';
@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
  constructor(
    public auth: AuthService,
    public router: Router,
    private currentUserService: CurrentUserService
  ) {}
  canActivate(route: ActivatedRouteSnapshot): boolean {
    if (!this.auth.isAuthenticated()) {
      this.router.navigate([`/security/login`], {
        queryParams: { last: this.router.url },
      });
      return false;
    }

    if (!route.data['allowedPermission']) {
      return true;
    } else {
      const hasPermission = this.currentUserService.hasPermission(
        route.data['allowedPermission']
      );
      if (hasPermission) {
        return true;
      } else {
        this.router.navigate(['/marsh/unauthorized-access']);
      }
    }
  }
}
