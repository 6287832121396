import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { EIPMAppSettings } from '../../../../eipm/eipm-app.settings';
import { Settings } from '../../../../app.settings.model';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
})
export class ErrorComponent {
  public settings: Settings;
  constructor(public appSettings: EIPMAppSettings, public router: Router) {
    this.settings = this.appSettings.settings;
  }

  goHome(): void {
    this.router.navigate(['/']);
  }

  ngAfterViewInit() {
    this.settings.loadingSpinner = false;
  }
}
