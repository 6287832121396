import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { EIPMAppSettings } from '../../../../eipm/eipm-app.settings';
import { Settings } from '../../../../app.settings.model';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent {
  public settings: Settings;
  constructor(public appSettings: EIPMAppSettings, public router: Router) {
    this.settings = this.appSettings.settings;
  }

  searchResult(): void {
    this.router.navigate(['/eipm/search']);
  }

  ngAfterViewInit() {
    this.settings.loadingSpinner = false;
  }
}
