import { Injectable } from '@angular/core';
import { Settings } from '../app.settings.model';

@Injectable({ providedIn: 'root' })
export class EIPMAppSettings {
  public settings = new Settings(
    'EIPM', // theme name
    true, // loadingSpinner
    false, // fixedHeader
    true, // sidenavIsOpened
    true, // sidenavIsPinned
    true, // sidenavUserBlock
    'horizontal', // horizontal , vertical
    'default', // default, compact, mini
    'red-light', // indigo-light, teal-light, red-light, blue-dark, green-dark, pink-dark
    false, // true = rtl, false = ltr
    true // true = has footer, false = no footer
  );
}
