import { AgmCoreModule } from '@agm/core';
import { OverlayContainer } from '@angular/cdk/overlay';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JWT_OPTIONS, JwtHelperService } from '@auth0/angular-jwt';
import { DocumentViewerModule } from '@core/document-viewer/document-viewer.module';
import { AuthInterceptor } from '@core/Interceptors/AuthInterceptor';
import { ErrorComponent } from '@shared/pages/errors/error/error.component';
import { NotFoundComponent } from '@shared/pages/errors/not-found/not-found.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { BlockUIModule } from 'ng-block-ui';
import { MomentModule } from 'ngx-moment';
import {
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface,
  PerfectScrollbarModule,
} from 'ngx-perfect-scrollbar';

import { AppComponent } from './app.component';
import { routing } from './app.routing';
import { EIPMPagesComponent } from './eipm/pages/pages.component';
import { SearchComponent } from './shared/pages/search/search.component';
import { SharedModule } from './shared/shared.module';
import { VerticalMenuComponent } from './theme/components/menu/vertical-menu/vertical-menu.component';
import { ThemeComponentsModule } from './theme/components/theme-components.module';
import { PipesModule } from './theme/pipes/pipes.module';
import { CustomOverlayContainer } from './theme/utils/custom-overlay-container';
import { AppSettings } from './app.settings';
import { ProcessComponent } from './atm/shared/process/process.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelPropagation: true,
  suppressScrollX: true,
};
@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BlockUIModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAir4tXhx3X-wcdZnhe8TLlo9J2m_AKx6w',
    }),
    PerfectScrollbarModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    SharedModule,
    MatMenuModule,
    PipesModule,
    MomentModule,
    routing,
    DocumentViewerModule,
    ThemeComponentsModule,
  ],
  declarations: [
    AppComponent,
    EIPMPagesComponent,
    SearchComponent,
    NotFoundComponent,
    ErrorComponent,
    ProcessComponent,
  ],
  entryComponents: [VerticalMenuComponent],
  providers: [
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    AppSettings,
    JwtHelperService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    // { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    { provide: OverlayContainer, useClass: CustomOverlayContainer },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
