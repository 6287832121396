import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {
  ControlContainer,
  FormBuilder,
  FormGroup,
  NgForm,
  Validators,
} from '@angular/forms';

import { PDFDocument } from './document-viewer.models';
import { DocumentViewerService } from './document-viewer.service';

(<any>window).pdfWorkerSrc = '/assets/javascript/pdf.worker/pdf.worker.min.js';

@Component({
  selector: 'app-document-viewer',
  templateUrl: './document-viewer.template.html',
  styleUrls: ['./document-viewer.style.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('fadeIn', [
      transition(':enter', [
        style({ opacity: '0' }),
        animate('1s ease-out', style({ opacity: '1' })),
      ]),
    ]),
    trigger('fadeOut', [
      transition(':leave', [
        style({ opacity: '1' }),
        animate('1s ease-out', style({ opacity: '0' })),
      ]),
    ]),
  ],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class DocumentViewerComponent implements OnInit {
  documents: Array<PDFDocument>;

  isLoading = true;

  showSendEmailView = false;

  testURL: string;
  dialogRef: any;
  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private documentViewerService: DocumentViewerService
  ) {
    this.form = fb.group({
      emailTo: ['', Validators.required],
      subject: ['', Validators.required],
      mailBody: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(500),
        ]),
      ],
    });
  }

  ngOnInit(): void {
    // clear previous documents
    this.documentViewerService.allDocuments = [];

    this.documents.forEach((doc) => {
      if (doc.pdfViewerSRC && doc.pdfViewerSRC !== '') {
        this.isLoading = false;
      } else {
        this.getDocuments(doc);
      }
    });
  }

  close() {
    this.dialogRef.close(false);
  }

  getDocuments(document: PDFDocument) {
    this.documentViewerService.getDocument(document).subscribe(
      (res) => {
        document.pdfViewerSRC = res;
        this.isLoading = false;
      },
      (error: any) => {
        this.isLoading = false;
      }
    );
  }

  downloadAllFile() {
    // download ones with link
    this.documents.forEach((x) => {
      if (x.pdfViewerSRC) {
        window.open(x.pdfViewerSRC, '_blank');
      }
    });
    // download one with blob
    this.documentViewerService.downLoadAll();
  }

  cancelSendingEmail() {
    this.form.reset();
    this.showSendEmailView = false;
  }
}
