import { Injectable } from '@angular/core';
import { SnackBarCustomComponent } from '@core/snackbar-custom/snackbar-custom.component';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({ providedIn: 'root' })
export class SnackBarCustomService {
  constructor(public snackBar: MatSnackBar) {}

  snack_bar_color_class: string[] = [
    'custom-info',
    'custom-success',
    'custom-warning',
    'custom-error'
  ];

  private snackBarRef: any;

  public open(msg, snackBarColor) {
    this.snackBarRef = this.snackBar.openFromComponent(
      SnackBarCustomComponent,
      {
        duration: 5000,
        panelClass: [this.snack_bar_color_class[snackBarColor]],
        announcementMessage: msg
      }
    );
    this.snackBarRef.instance.snackBarRefComponent = this.snackBarRef;
    this.snackBarRef.instance.message = msg;
  }
}
