import { ModuleWithProviders } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { ErrorComponent } from '@shared/pages/errors/error/error.component';
import { NotFoundComponent } from '@shared/pages/errors/not-found/not-found.component';
import { SearchComponent } from '@shared/pages/search/search.component';

import { EIPMPagesComponent } from './eipm/pages/pages.component';
import { AuthGuardService } from './security/shared/auth-guard.service';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./shared/pages/landing/landing.module').then(
        (m) => m.LandingModule
      ),
  },
  {
    path: 'bpm',
    loadChildren: () =>
      import('./bpm/pages/pages.module').then((m) => m.BPMPagesModule),
  },
  {
    path: 'atm',
    loadChildren: () =>
      import('./atm/pages/pages.module').then((m) => m.ATMPagesModule),
  },
  {
    path: 'ams',
    loadChildren: () =>
      import('./ams/pages/pages.module').then((m) => m.AMSPagesModule),
  },
  {
    path: 'eipm',
    component: EIPMPagesComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./eipm/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
        data: { breadcrumb: 'Dashboard' },
        canActivate: [AuthGuardService],
      },
      {
        path: 'workspace',
        loadChildren: () =>
          import('./eipm/pages/workspace/workspace.module').then(
            (m) => m.WorkspaceModule
          ),
        data: { breadcrumb: 'Project Execution' },
        canActivate: [AuthGuardService],
      },
      {
        path: 'activity/:activityId',
        loadChildren: () =>
          import(
            './eipm/projects/milestones/activities/activity-detail/activity-detail.module'
          ).then((m) => m.ActivityDetailModule),
        data: { breadcrumb: 'Activity' },
        canActivate: [AuthGuardService],
      },
      {
        path: 'milestone/:milestoneId',
        loadChildren: () =>
          import(
            './eipm/projects/milestones/milestone-detail/milestone-detail.module'
          ).then((m) => m.MilestoneDetailModule),
        data: { breadcrumb: 'Milestone' },
        canActivate: [AuthGuardService],
      },
      {
        path: 'activity/:activityId/new-issue',
        loadChildren: () =>
          import(
            './eipm/projects/issues/issue-detail/issue-detail.module'
          ).then((m) => m.IssueDetailModule),
        data: { breadcrumb: 'Activity' },
        canActivate: [AuthGuardService],
      },
      {
        path: 'issue/:issueId',
        loadChildren: () =>
          import(
            './eipm/projects/issues/issue-detail/issue-detail.module'
          ).then((m) => m.IssueDetailModule),
        data: { breadcrumb: 'Project Issue' },
        canActivate: [AuthGuardService],
      },
      {
        path: 'escalation/:escalationId',
        loadChildren: () =>
          import(
            './eipm/projects/escalations/escalation-detail/escalation-detail.module'
          ).then((m) => m.EscalationDetailModule),
        data: { breadcrumb: 'Escalation' },
        canActivate: [AuthGuardService],
      },
      {
        path: 'milestone/:milestoneId/payments',
        loadChildren: () =>
          import(
            './eipm/projects/milestones/milestone-list/milestone-payments/milestone-payment-list/milestone-payment-list.module'
          ).then((m) => m.MilestonePaymentListModule),
        data: { breadcrumb: 'Milestone Payments' },
        canActivate: [AuthGuardService],
      },
      {
        path: 'projects',
        loadChildren: () =>
          import('./eipm/projects/projects.module').then(
            (m) => m.ProjectsModule
          ),
        data: { breadcrumb: 'Project Admin' },
        canActivate: [AuthGuardService],
      },
      {
        path: 'setup',
        loadChildren: () =>
          import('./shared/pages/settings/settings.module').then(
            (m) => m.SettingsModule
          ),
        data: { breadcrumb: 'Setup' },
        canActivate: [AuthGuardService],
      },
      {
        path: 'fixed-asset-register',
        loadChildren: () =>
          import(
            './eipm/pages/fixed-asset-register/fixed-asset-register.module'
          ).then((m) => m.FixedAssetRegisterModule),
        data: { breadcrumb: 'Fixed Asset Register' },
        canActivate: [AuthGuardService],
      },
      {
        path: 'fixed-asset-register-details/:fixedAssetRegisterId',
        loadChildren: () =>
          import(
            './eipm/pages/fixed-asset-register/fixed-asset-register-detail/fixed-asset-register-detail.module'
          ).then((m) => m.FixedAssetRegisterDetailModule),
        data: { breadcrumb: 'Fixed Asset Register' },
        canActivate: [AuthGuardService],
      },
      {
        path: 'faults',
        loadChildren: () =>
          import('./eipm/pages/reported-faults/reported-faults.module').then(
            (m) => m.ReportedFaultsModule
          ),
        data: { breadcrumb: 'Faults' },
        canActivate: [AuthGuardService],
      },
      {
        path: 'supply-chain',
        loadChildren: () =>
          import('./eipm/pages/supply-chain/supply-chain.module').then(
            (m) => m.SupplyChainModule
          ),
        data: { breadcrumb: 'Supply Chain' },
        canActivate: [AuthGuardService],
      },
      {
        path: 'reports',
        loadChildren: () =>
          import('./eipm/pages/reports/reports.module').then(
            (m) => m.ReportsModule
          ),
        data: { breadcrumb: 'Reports' },
        canActivate: [AuthGuardService],
      },
      {
        path: 'drag-drop',
        loadChildren: () =>
          import('./eipm/pages/drag-drop/drag-drop.module').then(
            (m) => m.DragDropModule
          ),
        data: { breadcrumb: 'Drag & Drop' },
        canActivate: [AuthGuardService],
      },
      {
        path: 'schedule',
        loadChildren: () =>
          import('./eipm/schedule/schedule.module').then(
            (m) => m.ScheduleModule
          ),
        data: { breadcrumb: 'Schedule' },
        canActivate: [AuthGuardService],
      },
      {
        path: 'notifications',
        loadChildren: () =>
          import('./eipm/pages/mailbox/mailbox.module').then(
            (m) => m.MailboxModule
          ),
        data: { breadcrumb: 'Notifications' },
        canActivate: [AuthGuardService],
      },

      {
        path: 'profile',
        loadChildren: () =>
          import('./eipm/setup/profile/profile.module').then(
            (m) => m.ProfileModule
          ),
        data: { breadcrumb: 'Profile' },
        canActivate: [AuthGuardService],
      },
      {
        path: 'search',
        component: SearchComponent,
        data: { breadcrumb: 'Search' },
        canActivate: [AuthGuardService],
      },
    ],
  },
  {
    path: 'security',
    loadChildren: () =>
      import('./security/security.module').then((m) => m.SecurityModule),
  },
  {
    path: 'fault',
    loadChildren: () =>
      import(
        './eipm/pages/reported-faults/report-fault/report-fault.module'
      ).then((m) => m.ReportFaultModule),
  },
  { path: 'error', component: ErrorComponent, data: { breadcrumb: 'Error' } },
  { path: '**', redirectTo: '/' },
];

export const routing: ModuleWithProviders = RouterModule.forRoot(routes, {
  preloadingStrategy: PreloadAllModules, // <- comment this line for enable lazy load
  // useHash: true
});
