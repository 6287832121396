import { Injectable } from '@angular/core';
import { Settings } from './app.settings.model';

@Injectable()
export class AppSettings {
  public settings = new Settings(
    'EIPM', // theme name
    true, // loadingSpinner
    true, // fixedHeader
    true, // sidenavIsOpened
    true, // sidenavIsPinned
    true, // sidenavUserBlock
    'horizontal', // horizontal , vertical
    'default', // default, compact, mini
    'uif', // 'uif', 'emg', 'cf' ,'custom-light-blue', indigo-light, teal-light, red-light, blue-dark, green-dark, pink-dark
    false, // true = rtl, false = ltr
    true // true = has footer, false = no footer
  );
}
