import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'MilestoneSearchPipe', pure: false })
export class MilestoneSearchPipe implements PipeTransform {
  transform(value, args?): Array<any> {
    const searchText = new RegExp(args, 'ig');
    if (value) {
      return value.filter(milestone => {
        if (milestone.profile?.name) {
          return milestone.profile.name.search(searchText) !== -1;
        } else {
          return milestone.description
            ? milestone.description.search(searchText) !== -1
            : true;
        }
      });
    }
  }
}
