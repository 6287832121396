import { Injectable } from '@angular/core';
import { Router, Params } from '@angular/router';

import { CurrentUser } from './current-user.model';

@Injectable({
  providedIn: 'root',
})
export class CurrentUserService {
  private _currentUser: CurrentUser;

  set currentUser(currentUser: CurrentUser) {
    this._currentUser = currentUser;
  }

  get currentUser(): CurrentUser {
    if (!this._currentUser) {
      this.getCurrentUser();
    }
    return this._currentUser;
  }

  constructor(private router: Router) {}

  hasRole(roleName: string) {
    const role = this._currentUser.roles.find(x => x.name == roleName);
    return !!role;
  }

  hasPermission(allowedPermission) {
    if (!allowedPermission) {
      return true;
    }
    const permissionGroup = allowedPermission.split('.')[0];
    const permission = allowedPermission.split('.')[1];

    if (!permissionGroup || !permission) {
      return false;
    }

    if (this.currentUser.permissions) {
      const userPermission = this.currentUser.permissions.find((p) => {
        return p.name === permissionGroup;
      });

      if (!userPermission) {
        return false;
      } else {
        const fieldName =
          'allow' + permission[0].toUpperCase() + permission.substring(1);
        return userPermission[fieldName] === 1;
      }
    }
    return false;
  }

  private getCurrentUser() {
    const storedUser = JSON.parse(localStorage.getItem('currentUser'));
    if (!storedUser) {
      // user not found
      this.router.navigate([`/security/login`], {
        queryParams: { last: this.router.url },
      });
      return;
    }
    this._currentUser = storedUser;
  }
}
