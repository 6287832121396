import { Router } from '@angular/router';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CurrentUser } from 'src/app/security/shared/current-user.model';
import { CurrentUserService } from 'src/app/security/shared/current-user.service';
import { AuthService } from 'src/app/security/shared/auth.service';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserMenuComponent implements OnInit {
  public userImage = '../assets/img/avatars/avatar_default.png';
  currentUser: CurrentUser;
  constructor(
    private currentUserService: CurrentUserService,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit() {
    this.currentUser = this.currentUserService.currentUser;
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['']);
  }
}
