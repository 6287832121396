import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class BlockUIServiceCustom {
  private blockUI: NgBlockUI;
  loading$ = new BehaviorSubject<boolean>(false);

  constructor() {}
  setNgBlockUI(blockUI: NgBlockUI): void {
    this.blockUI = blockUI;
  }
  startBlockUI(message?: string, block: boolean = false): void {
    if (block) {
      this.blockUI.start(message);
    } else {
      this.loading$.next(true);
    }
  }
  updateBlockUI(message?: string): void {
    this.blockUI.update(message);
  }

  stopBlockUI(): void {
    this.blockUI.stop();
    this.loading$.next(false);
  }
}
