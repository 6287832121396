import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@shared/shared.module';
import { MomentModule } from 'ngx-moment';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';

import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { FullScreenComponent } from './fullscreen/fullscreen.component';
import { HorizontalMenuComponent } from './menu/horizontal-menu/horizontal-menu.component';
import { VerticalMenuComponent } from './menu/vertical-menu/vertical-menu.component';
import { MessagesComponent } from './messages/messages.component';
import { SideMenuNavComponent } from './side-menu-nav/side-menu-nav.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { UserMenuComponent } from './user-menu/user-menu.component';

@NgModule({
  declarations: [
    SidenavComponent,
    SideMenuNavComponent,
    VerticalMenuComponent,
    HorizontalMenuComponent,
    BreadcrumbComponent,
    FullScreenComponent,
    MessagesComponent,
    UserMenuComponent,
  ],
  imports: [CommonModule, RouterModule, SharedModule, PerfectScrollbarModule, 
    MomentModule],
  exports: [
    SidenavComponent,
    SideMenuNavComponent,
    VerticalMenuComponent,
    HorizontalMenuComponent,
    BreadcrumbComponent,
    FullScreenComponent,
    MessagesComponent,
    UserMenuComponent,
  ],
})
export class ThemeComponentsModule {}
