import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class MessagesService {
  public url = environment.apiUrl + '/notifications';
  headers: HttpHeaders;
  get options() {
    return { headers: this.headers, params: null };
  }

  constructor(public http: HttpClient) {}

  getNotifications(
    filter = [],
    sortDirection = '[]',
    pageIndex = 0,
    pageSize = 3
  ): Observable<any> {
    const params = new HttpParams()
      .set('filter', JSON.stringify(filter))
      .set('sortOrder', sortDirection)
      .set('pageNumber', pageIndex.toString())
      .set('pageSize', pageSize.toString());

    const options = this.options;
    options.params = params;

    return this.http.get<any>(this.url, options);
  }

  getUnReadNotifications(
    filter = [],
    sortDirection = '[]',
    pageIndex = 0,
    pageSize = 10
  ): Observable<any> {
    const params = new HttpParams()
      .set('filter', JSON.stringify(filter))
      .set('sortOrder', sortDirection)
      .set('pageNumber', pageIndex.toString())
      .set('pageSize', pageSize.toString());

    const options = this.options;
    options.params = params;

    return this.http.get<any>(this.url + '/unread/message', options);
  }

  getNewNotifications(
    filter = [],
    sortDirection = '[]',
    pageIndex = 0,
    pageSize = 10
  ): Observable<any> {
    const params = new HttpParams()
      .set('filter', JSON.stringify(filter))
      .set('sortOrder', sortDirection)
      .set('pageNumber', pageIndex.toString())
      .set('pageSize', pageSize.toString());

    const options = this.options;
    options.params = params;

    return this.http.get<any>(this.url + '/new/message', options);
  }

  
  markMessagesAsSeen(
    notificationIds
  ): Observable<any> {
    const params = new HttpParams()
      .set('notificationIds', JSON.stringify(notificationIds))

    const options = this.options;
    options.params = params;

    return this.http.put<any>(this.url + '/mark/as/seen', notificationIds , options);
  }


  public getMessages(): Array<any> {
    return [];
  }

  public getFiles(): Array<any> {
    return [];
  }

  public getMeetings(): Array<any> {
    return [];
  }
}
