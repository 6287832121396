import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class NotificationsService {
  // signalR connection reference
  private connection: SignalR;
  private proxy: SignalR.Hub.Proxy;
  messageNotification$ = new BehaviorSubject<any>(null);

  constructor() {
    const conn = $.hubConnection(environment.apiUrl.replace('/api3/api', ''));
    this.proxy = conn.createHubProxy('notificationHub');

    // define a callback method for proxy
    this.proxy.on('newNotification', (latestMsg) =>
      this.onNotificationReceived(latestMsg)
    );

    this.proxy.on('hello', (latestMsg) => this.onHello(latestMsg));

    // this.connection.hub.start();
    conn.start().then(() => {});
  }

  private onNotificationReceived(latestMsg: string) {
    console.log('New message received: ' + latestMsg);
  }

  private onHello(message: any) {
    this.messageNotification$.next(message);
  }

  // method for sending message
  broadcastMessage(msg: string) {
    // invoke method by its name using proxy
    this.proxy.invoke('Hello', msg);
    this.proxy.invoke('SayHello', msg);
  }
}
