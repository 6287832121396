import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';
import { Project, ProjectDashboard } from './project.model';
import { PagedResult } from '@core/models';

@Injectable({ providedIn: 'root' })
export class ProjectsService {
  public url = environment.apiUrl + '/projects';
  headers: HttpHeaders;
  get options() {
    return { headers: this.headers, params: null };
  }
  constructor(public http: HttpClient) {}

  get(id): Observable<Project> {
    return this.http.get<Project>(this.url + '/' + id);
  }

  getProjects(): Observable<Project[]> {
    return this.http.get<Project[]>(this.url);
  }

  getRecentProjects(): Observable<Project[]> {
    return this.http.get<Project[]>(this.url + '/recent');
  }

  getProjectsExecutionSummaries(): Observable<Project[]> {
    return this.http.get<Project[]>(
      this.url + '/execution-summaries/paged/results'
    );
  }

  getProjectsSummariesPagedResults(
    filter = [],
    sortDirection = '[]',
    pageIndex = 1,
    pageSize = 10
  ): Observable<PagedResult<Project>> {
    const params = new HttpParams()
      .set('filter', JSON.stringify(filter))
      .set('sortOrder', sortDirection)
      .set('pageNumber', pageIndex.toString())
      .set('pageSize', pageSize.toString());

    const options = this.options;
    options.params = params;

    const url = `${this.url}/planning/paged/results`;

    return this.http.get<PagedResult<Project>>(url, options);
  }

  getProjectsPagedResults(
    filter: any = null,
    sortDirection = '[]',
    pageIndex = 0,
    pageSize = 3
  ): Observable<PagedResult<Project>> {
    const params = new HttpParams()
      .set('customFilter', filter.customFilter)
      .set('status', filter.status)
      .set('projectTypeId', filter.projectTypeId)
      .set('sortOrder', sortDirection)
      .set('pageNumber', pageIndex.toString())
      .set('pageSize', pageSize.toString());

    const options = this.options;
    options.params = params;

    const url = `${this.url}/paged/results`;

    return this.http.get<PagedResult<Project>>(url, options);
  }

  getProjectWithMilestonesAndActivities(projectId): Observable<Project> {
    const url = `${environment.apiUrl}/projects/${projectId}/milestones-activities`;
    return this.http.get<Project>(url);
  }

  getUserProjectsMilestones(): Observable<Project[]> {
    const url = `${environment.apiUrl}/projects/user-project-milestones`;
    return this.http.get<Project[]>(url);
  }

  getUserProjectsActivities(showOnlyRiskToTime = false): Observable<Project[]> {
    const params = new HttpParams().set(
      'showOnlyRiskToTime',
      showOnlyRiskToTime.toString()
    );

    const options = this.options;
    options.params = params;
    const url = `${environment.apiUrl}/projects/user-project-milestones-activities`;
    return this.http.get<Project[]>(url, options);
  }

  getMilestoneActivities(milestoneId: number): Observable<Project[]> {
    const url = `${environment.apiUrl}/projects/milestone/${milestoneId}/project-milestones-activities`;
    return this.http.get<Project[]>(url);
  }

  getUserProjectsSupplyChainActivities() {
    const url = `${environment.apiUrl}/projects/user/milestones/supply-chain-activities`;
    return this.http.get<Project[]>(url);
  }

  getUserProjectsEscalations(): Observable<Project[]> {
    const url = `${environment.apiUrl}/projects/escalations`;
    return this.http.get<Project[]>(url);
  }

  addProject(project: Project) {
    return this.http.post(this.url, project);
  }

  startProject(project: any) {
    return this.http.put(
      this.url + '/' + project.id + '/complete-planning',
      project
    );
  }

  changeProjectStatus(project: Project) {
    return this.http.put(this.url + '/' + project.id + '/status', project);
  }

  updateProject(project: Project) {
    return this.http.put(this.url + '/' + project.id, project);
  }

  deleteProject(id: number) {
    return this.http.delete(this.url + '/' + id);
  }

  getProjectApprovals(id: any): Observable<any> {
    const url = `${environment.apiUrl}/project-compliances/project/${id}`;
    return this.http.get<any>(url);
  }

  saveProjectApproval(projectApproval: any): Observable<any> {
    const url = `${environment.apiUrl}/project-compliances`;
    return this.http.post(url, projectApproval);
  }
}
