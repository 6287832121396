import { AfterViewInit, Directive, ElementRef, Input, OnInit } from '@angular/core';

import { CurrentUserService } from '../../security/shared/current-user.service';

@Directive({
  selector: '[disableIfUnauthorized]'
})
export class DisableIfUnauthorizedDirective implements OnInit, AfterViewInit {
  @Input()
  disableIfUnauthorized: string;
  constructor(private el: ElementRef, private currentUserService: CurrentUserService) {}
  ngOnInit() {}
  ngAfterViewInit(): void {
    if (!this.currentUserService.hasPermission(this.disableIfUnauthorized)) {
      this.el.nativeElement.disabled = true;
    }
  }
}
