import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable()
export class BaseHttpService {
  apiUrl = environment.apiUrl;
  tokenUrl = environment.tokenUrl;
  headers: HttpHeaders;
  http: HttpClient;
  get options() {
    return { headers: this.headers, params: null };
  }

  constructor(public injector: Injector) {
    this.http = injector.get(HttpClient);

    this.headers = new HttpHeaders();
    this.headers = this.headers
      .set('Content-Type', 'application/json; charset=utf-8')
      .set('Cache-Control', 'no-cache')
      .set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT');
  }
}
