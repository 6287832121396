import {
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { AppSettings } from 'src/app/app.settings';
import { CurrentUser } from 'src/app/security/shared/current-user.model';
import { CurrentUserService } from 'src/app/security/shared/current-user.service';

import { Settings } from '../../../app.settings.model';
import { Menu } from '../menu/menu.model';
import { MenuService } from '../menu/menu.service';

@Component({
  selector: 'app-side-menu-nav',
  templateUrl: './side-menu-nav.component.html',
  styleUrls: ['./side-menu-nav.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [MenuService],
})
export class SideMenuNavComponent implements OnInit {
  public userImage = '../assets/img/users/user.jpg';
  public menuItems: Array<any>;
  public settings: Settings;
  currentUser: CurrentUser;
  @Input() systemName = '';
  @ViewChild('sideMenu') sideMenu: ElementRef;
  @Input() menus: Menu[];
  constructor(
    public appSettings: AppSettings,
    public menuService: MenuService,
    private currentUserService: CurrentUserService
  ) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit() {
    const context = this;
    this.currentUser = this.currentUserService.currentUser;
  }

  toggle(index: number) {
    this.menus
      .filter((menu, i) => i !== index && menu.active)
      .forEach((menu) => (menu.active = !menu.active));

    this.menus[index].active = !this.menus[index].active;
  }
}
