import { Menu } from './menu.model';

export const verticalMenuItems = [
  new Menu(
    1,
    'Dashboard',
    '/',
    null,
    'insert_chart_outlined',
    null,
    false,
    0,
    'dashboard.view'
  ),
  new Menu(
    2,
    'Projects',
    '/projects/list',
    null,
    'work_outline',
    null,
    false,
    0,
    'project-create.view'
  ),
  new Menu(
    2,
    'Project Planning',
    '/projects',
    null,
    'work_outline',
    null,
    false,
    0,
    'project-create.view'
  ),
  new Menu(
    11,
    'Project Execution',
    '/workspace',
    null,
    'dashboard',
    null,
    false,
    0
  ),
  // new Menu(
  //   111,
  //   'Overview',
  //   '/workspace',
  //   null,
  //   'bookmark_border',
  //   null,
  //   false,
  //   11
  // ),
  // new Menu(
  //   112,
  //   'Milestones',
  //   '/workspace/my-milestones',
  //   null,
  //   '',
  //   null,
  //   false,
  //   11,
  //   'milestones.view'
  // ),
  // new Menu(
  //   112,
  //   'Activities',
  //   '/workspace/my-activities',
  //   null,
  //   '',
  //   null,
  //   false,
  //   11,
  //   'activities .view'
  // ),
  // // new Menu(
  // //   112,
  // //   'Project Specifications',
  // //   '/workspace/project-specifications',
  // //   null,
  // //   '',
  // //   null,
  // //   false,
  // //   11,
  // //   'project-specifications.view'
  // // ),
  // new Menu(
  //   112,
  //   'Project Compliance',
  //   '/workspace/compliances',
  //   null,
  //   '',
  //   null,
  //   false,
  //   11,
  //   'project-compliances.view'
  // ),
  // // new Menu(
  // //   112,
  // //   'Completion Certificate',
  // //   '/workspace/project-specifications',
  // //   null,
  // //   '',
  // //   null,
  // //   false,
  // //   11,
  // //   'completion-certificate.view'
  // // ),
  // new Menu(
  //   112,
  //   'Assets',
  //   '/workspace/assets',
  //   null,
  //   '',
  //   null,
  //   false,
  //   11,
  //   'assets.view'
  // ),
  // new Menu(
  //   112,
  //   'Tenders',
  //   '/supply-chain/tenders',
  //   null,
  //   '',
  //   null,
  //   false,
  //   11,
  //   'tenders.view'
  // ),
  // new Menu(
  //   112,
  //   'Panels',
  //   '/supply-chain/panels',
  //   null,
  //   '',
  //   null,
  //   false,
  //   11,
  //   'panels.view'
  // ),
  // new Menu(
  //   112,
  //   'Supply Chain Activities',
  //   '/workspace/supply-chain-activities',
  //   null,
  //   '',
  //   null,
  //   false,
  //   11,
  //   'scm-activities.view'
  // ),
  // new Menu(
  //   113,
  //   'Open issue',
  //   '/workspace/open-issues',
  //   null,
  //   '',
  //   null,
  //   false,
  //   11,
  //   'issues.view'
  // ),
  // new Menu(
  //   114,
  //   'All issues',
  //   '/workspace/all-issues',
  //   null,
  //   '',
  //   null,
  //   false,
  //   11,
  //   'issues.view'
  // ),
  // // new Menu(
  // //   116,
  // //   'Comments from team Leader',
  // //   '/workspace/comments',
  // //   null,
  // //   '',
  // //   null,
  // //   false,
  // //   11,
  // //   'comments-feed.view'
  // // ),

  // new Menu(
  //   117,
  //   'Escalations',
  //   '/workspace/escalations',
  //   null,
  //   '',
  //   null,
  //   false,
  //   11,
  //   'escalations.view'
  // ),
  // new Menu(
  //   6,
  //   'Project Monitoring',
  //   '/project-board',
  //   null,
  //   'event',
  //   null,
  //   false,
  //   0
  //   // 'project-monitoring.view'
  // ),
  // new Menu(4, 'Notifications', '/notifications', null, 'email', null, false, 0),
  // new Menu(5, 'Schedule', '/schedule', null, 'event', null, false, 0),
  new Menu(
    3,
    'Reports',
    '/reports',
    null,
    'insert_chart_outlined',
    null,
    false,
    0,
    'reports.view'
  ),

  new Menu(
    6,
    'Setup',
    '/setup',
    null,
    'settings',
    null,
    false,
    0,
    'setup.view'
  ),
];

export const horizontalMenuItems = [
  new Menu(
    11,
    'Project Execution',
    '/workspace',
    null,
    'dashboard',
    null,
    true,
    0
  ),
  new Menu(1, 'Dashboard', '/', null, 'insert_chart_outlined', null, false, 0),
  new Menu(
    111,
    'Overview',
    '/workspace',
    null,
    'bookmark_border',
    null,
    false,
    11
  ),
  new Menu(
    111,
    'My Projects',
    '/workspace/my-projects',
    null,
    'bookmark_border',
    null,
    false,
    11
  ),
  new Menu(
    112,
    'Milestone',
    '/workspace/my-milestones',
    null,
    '',
    null,
    false,
    11
  ),
  new Menu(
    112,
    'Activities',
    '/workspace/my-activities',
    null,
    '',
    null,
    false,
    11
  ),
  new Menu(
    112,
    'Supply chain Activities',
    '/workspace/supply-chain-activities',
    null,
    '',
    null,
    false,
    11
  ),
  new Menu(
    113,
    'All My Issues',
    '/workspace/open-issues',
    null,
    '',
    null,
    false,
    11
  ),
  new Menu(
    114,
    'Unapproved Issues',
    '/workspace/all-issues',
    null,
    '',
    null,
    false,
    11
  ),
  new Menu(
    115,
    'Approved Issues',
    '/workspace/approved-issues',
    null,
    '',
    null,
    false,
    11
  ),
  // new Menu(
  //   116,
  //   'Comments from team Leader',
  //   '/workspace/comments',
  //   null,
  //   '',
  //   null,
  //   false,
  //   11
  // ),
  new Menu(
    117,
    'Escalations',
    '/workspace/escalations',
    null,
    '',
    null,
    false,
    11
  ),
  new Menu(2, 'Project', '/projects', null, '', null, false, 0),
  // new Menu(4, 'Notifications', '/notifications', null, 'email', null, false, 0),
  new Menu(5, 'Schedule', '/schedule', null, 'event', null, false, 0),
  new Menu(
    3,
    'Reports',
    '/reports',
    null,
    'insert_chart_outlined',
    null,
    false,
    0
  ),
  new Menu(6, 'Supply Chain', '/supply-chain', null, 'event', null, false, 0),
];
