import { Component } from '@angular/core';

@Component({
  selector: 'app-core-snack-bar',
  templateUrl: 'snackbar-custom.component.html'
})
export class SnackBarCustomComponent {
  public snackBarRefComponent: any;

  message: string;

  close() {
    this.snackBarRefComponent.dismiss();
  }
}
