import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '@core/confirm-dialog/confirm-dialog.component';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ConfirmDialogService {
  constructor(private dialog: MatDialog) {}

  public confirm(
    title: string,
    message: string,
    okButtonLabel?: string,
    cancelButtonLabel?: string,
    showCancelButton?: boolean
  ): Observable<boolean> {
    let dialogRef: MatDialogRef<ConfirmDialogComponent>;

    dialogRef = this.dialog.open(ConfirmDialogComponent);

    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.message = message;
    dialogRef.componentInstance.okButtonLabel = okButtonLabel;
    dialogRef.componentInstance.cancelButtonLabel = cancelButtonLabel;
    dialogRef.componentInstance.showCancelButton =
      showCancelButton === undefined ? true : showCancelButton;

    return dialogRef.afterClosed();
  }
}
