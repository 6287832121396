import { Component } from '@angular/core';
import { BlockUIServiceCustom } from '@core/services/block-ui.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

import { AppSettings } from './app.settings';
import { Settings } from './app.settings.model';
import { NotificationsService } from './shared/services/notifications.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  public settings: Settings;
  @BlockUI() blockUI: NgBlockUI;
  subdomain: string;
  constructor(
    public appSettings: AppSettings,
    public blockUIService: BlockUIServiceCustom
  ) {
    this.settings = this.appSettings.settings;

    // if (this.subdomain == 'uif') {
    //   this.settings.theme = 'uif';
    // }

    this.blockUIService.setNgBlockUI(this.blockUI);
  }

  getSubdomain() {
    const domain = window.location.hostname;
    if (
      domain.indexOf('.') < 0 ||
      domain.split('.')[0] === 'example' ||
      domain.split('.')[0] === 'lvh' ||
      domain.split('.')[0] === 'www'
    ) {
      this.subdomain = '';
    } else {
      this.subdomain = domain.split('.')[0];
    }
    console.log('subdomain', this.subdomain);
  }
}
