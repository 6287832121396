import { Observable } from 'rxjs';
import {
  Component,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { NavigationEnd, Router } from '@angular/router';
import { QuickReportService } from '@shared/services/quick-report.service';

import { EIPMAppSettings } from '../../../../eipm/eipm-app.settings';
import { Settings } from '../../../../app.settings.model';
import { MenuService } from '../menu.service';
import { ProjectsService } from '../../../../eipm/projects/projects.service';
import { Project } from 'src/app/eipm/projects/project.model';

@Component({
  selector: 'app-horizontal-menu',
  templateUrl: './horizontal-menu.component.html',
  styleUrls: ['./horizontal-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [MenuService],
})
export class HorizontalMenuComponent implements OnInit {
  @Input() menuParentId;
  public menuItems: Array<any>;
  public settings: Settings;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  @Input() menus: any[];
  recentProjects$: Observable<Project[]>;

  constructor(
    public appSettings: EIPMAppSettings,
    public menuService: MenuService,
    public router: Router,
    public quickReportService: QuickReportService,
    public projectService: ProjectsService
  ) {
    this.recentProjects$ = this.projectService.getRecentProjects();
    this.settings = this.appSettings.settings;
  }

  ngOnInit() {
    this.menuItems = this.menuService.getHorizontalMenuItems();
    this.menuItems = this.menuItems.filter(
      (item) => item.parentId === this.menuParentId
    );
  }

  ngAfterViewInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.settings.fixedHeader) {
          const mainContent = document.getElementById('main-content');
          if (mainContent) {
            mainContent.scrollTop = 0;
          }
        } else {
          document.getElementsByClassName(
            'mat-drawer-content'
          )[0].scrollTop = 0;
        }
      }
    });
  }

  openProject(id: number) {
    this.router.navigate([`/eipm/workspace/${id}/details/summary`]);
  }

  menuClick(menu) {
    if (menu.clickEvent.indexOf('report-') === 0) {
      this.quickReportService.showReport(
        menu.clickEvent.substring(7),
        menu.title
      );
    }
  }
}
