import { SNACK_BAR_COLOR } from '@core/enums';
import {
  HttpErrorResponse,
  HttpHandler,
  HttpHeaderResponse,
  HttpInterceptor,
  HttpProgressEvent,
  HttpRequest,
  HttpResponse,
  HttpSentEvent,
  HttpUserEvent,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs/internal/Observable';
import { tap } from 'rxjs/operators';
import { SnackBarCustomService } from '@core/snackbar-custom/snackbar-custom.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private snackBar: SnackBarCustomService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<
    | HttpSentEvent
    | HttpHeaderResponse
    | HttpProgressEvent
    | HttpResponse<any>
    | HttpUserEvent<any>
  > {
    const idToken = localStorage.getItem('id_token');

    if (idToken) {
      const cloned = req.clone({
        headers: req.headers
          .set('Authorization', 'Bearer ' + idToken)
          .set('Pragma', 'no-cache')
          .set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT')
          .set('If-Modified-Since', '0')
          .set('itrack-application', this.router.url.split('/')[1]),
      });

      return next.handle(cloned).pipe(
        tap(
          () => {},
          (err: any) => {
            if (err instanceof HttpErrorResponse) {
              if (err.status === 500) {
                this.snackBar.open('System is unable to retrieve data, Please try again later.', SNACK_BAR_COLOR.error);
                return;
              }
              if (err.status === 401) {
                this.router.navigate([`/security/login`], {
                  queryParams: { last: this.router.url },
                });
              }
              return;
            }
          }
        )
      );
    } else {
      return next.handle(req);
    }
  }
}
