import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'ProjectSearchPipe', pure: false })
export class ProjectSearchPipe implements PipeTransform {
  transform(value, args?): Array<any> {
    const searchText = new RegExp(args, 'ig');
    if (value) {
      return value.filter(project => {
        if (project.profile?.name) {
          return project.profile.firstName.search(searchText) !== -1;
        } else {
          return project.description
            ? project.description.search(searchText) !== -1
            : true;
        }
      });
    }
  }
}
