import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-process',
  templateUrl: './process.component.html',
  styleUrls: ['./process.component.scss']
})
export class ProcessComponent implements OnInit {

  testData = [
    {id:0, name: "sam"},
    {id:1, name: "sammy"},
    {id:2, name: "tam"},
    {id:3, name: "bam"},
  ]

  constructor() { }

  ngOnInit(): void {
  }

}
