import { MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'confirm-dialog',
  template: `
    <div style="padding:0 16px 0 16px;">
      <h2 class="accent-color">{{ title }}</h2>

      <p class="" [innerHTML]="message"></p>
      <div class="p-1"></div>
      <p style="display: flex;">
        <button
          type="button"
          mat-stroked-button
          color="warn"
          *ngIf="showCancelButton"
          (click)="dialogRef.close()"
        >
          <mat-icon>close</mat-icon>
          {{ cancelButtonLabel }}
        </button>
        <span class="spacer"></span>
        <button
          type="button"
          color="primary"
          mat-stroked-button
          style="margin-left: 10px;"
          (click)="dialogRef.close(true)"
        >
          <mat-icon>done</mat-icon>
          {{ okButtonLabel }}
        </button>
      </p>

      <p></p>
    </div>
  `,
})
export class ConfirmDialogComponent implements OnInit {
  public title: string;
  public message: string;
  public okButtonLabel: string;
  public cancelButtonLabel: string;
  public showCancelButton: boolean;

  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>) {}

  ngOnInit(): void {
    this.okButtonLabel = this.okButtonLabel ? this.okButtonLabel : 'OK';
    this.cancelButtonLabel = this.cancelButtonLabel
      ? this.cancelButtonLabel
      : 'Cancel';
  }
}
