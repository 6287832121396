import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BaseHttpService } from '@core/services/base.service';
import * as FileSaver from 'node_modules/file-saver';

import { environment } from '../../../environments/environment';
import { PDFDocument } from './document-viewer.models';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class DocumentViewerService extends BaseHttpService {
  dataLocalUrl: any;
  allDocuments: Array<PDFDocument>;
  headers: HttpHeaders;
  private serviceUrl = environment.apiUrl;

  constructor(public injector: Injector, public domSanitizer: DomSanitizer) {
    super(injector);

    this.allDocuments = new Array<PDFDocument>();

    this.headers = new HttpHeaders();
    this.headers = this.headers.set('Content-Type', 'application/text');
  }

  getDocument(doc: PDFDocument): Observable<any> {
    let fullUrl = '';
    if (doc.url.indexOf('http:/') === -1 && doc.url.indexOf('https:/') === -1) {
      fullUrl = `${environment.apiUrl}/${doc.url}`;
    } else {
      fullUrl = doc.url;
    }

    const lenderImages$ = this.http
      .get(fullUrl, {
        responseType: 'blob',
      })
      .pipe(
        map((data: any) => {
          const blob = data; // .blob();
          doc.pdfBlob = blob;
          this.allDocuments.push(doc);
          return URL.createObjectURL(blob);
        })
      );

    return lenderImages$;
  }

  public async downloadFile(doc) {
    const fullUrl = `${environment.apiUrl}/documents/${doc.id}/file`;

    return this.http
      .get(fullUrl, {
        responseType: 'blob',
      })
      .subscribe(
        (res: any) => {
          FileSaver.saveAs(res, doc.fileName.replace('"', '').replace('"', ''));
        },
        (error) => console.log('error', error)
      );
  }

  public downLoadAll() {
    this.allDocuments.forEach((doc: PDFDocument) => {
      const filename = doc.title + '.pdf';
      FileSaver.saveAs(doc.pdfBlob, filename);
    });
  }
}
