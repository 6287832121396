import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { BaseHttpService } from '@core/services/base.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PrintService } from './print.service';
import * as FileSaver from 'file-saver';

@Injectable({
  providedIn: 'root',
})
export class QuickReportService extends BaseHttpService {
  headers: HttpHeaders;

  constructor(
    public injector: Injector,
    public printService: PrintService,
    public domSanitizer: DomSanitizer
  ) {
    super(injector);

    this.headers = new HttpHeaders();
    this.headers = this.headers.set('Content-Type', 'application/text');
  }

  showReport(reportName: string, reportTitle: string) {
    this.getReportData(reportName, {}).subscribe((x) => {
      const reportConfig = this.getReportConfig(reportName);
      this.printService.print(x, reportConfig, reportTitle, true);
    });
  }

  downloadControlsReport(financialPeriodId, processId, statusId) {
    const fullUrl = `${this.apiUrl}/control-procedures/report/financial-period/${financialPeriodId}/process/${processId}/status/${statusId}`;

    const sub$ = this.http
      .get(fullUrl, {
        responseType: 'blob',
      })
      .pipe(
        map((data: any) => {
          const filename = 'KeyControlsReport.xlsx';
          FileSaver.saveAs(data, filename);
        })
      );
    return sub$;
  }

  downloadControlProgressReport(financialPeriodId, processId, statusId) {
    const fullUrl = `${this.apiUrl}/control-procedures/progress-report/financial-period/${financialPeriodId}/process/${processId}/status/${statusId}`;

    const sub$ = this.http
      .get(fullUrl, {
        responseType: 'blob',
      })
      .pipe(
        map((data: any) => {
          const filename = 'KeyControlsProgressReport.xlsx';
          FileSaver.saveAs(data, filename);
        })
      );
    return sub$;
  }

  downloadControlSummaryReport(financialPeriodId, processId) {
    const fullUrl = `${this.apiUrl}/control-procedures/summary-report/financial-period/${financialPeriodId}/process/${processId}`;

    const sub$ = this.http
      .get(fullUrl, {
        responseType: 'blob',
      })
      .pipe(
        map((data: any) => {
          const filename = 'KeyControlSummaryReport.xlsx';
          FileSaver.saveAs(data, filename);
        })
      );
    return sub$;
  }

  downloadControlShortSummaryReport(financialPeriodId, processId) {
    const fullUrl = `${this.apiUrl}/control-procedures/short-summary-report/financial-period/${financialPeriodId}/process/${processId}`;

    const sub$ = this.http
      .get(fullUrl, {
        responseType: 'blob',
      })
      .pipe(
        map((data: any) => {
          const filename = 'KeyControlQuickViewReport.xlsx';
          FileSaver.saveAs(data, filename);
        })
      );
    return sub$;
  }

  downloadAssetDetailedReport(financialPeriodId) {

    const params = new HttpParams().set(
      'financialPeriodIds',
      JSON.stringify(financialPeriodId)
    );
    

    const options = this.options;
    options.params = params;
    options['responseType'] = 'blob';

    const fullUrl = `${this.apiUrl}/asset-details/report/financial-period`;

    const sub$ = this.http
      .get(fullUrl, options)
      .pipe(
        map((data: any) => {
          const filename = 'Asset register report.xlsx';
          FileSaver.saveAs(data, filename);
        })
      );
    return sub$;
  }

  downloadProceduresReport() {
    const fullUrl = `${this.apiUrl}/control-procedures/data`;

    const sub$ = this.http
      .get(fullUrl, {
        responseType: 'blob',
      })
      .pipe(
        map((data: any) => {
          const filename = 'Procedures.xlsx';
          FileSaver.saveAs(data, filename);
        })
      );
    return sub$;
  }

  downloadRisksReport() {
    const fullUrl = `${this.apiUrl}/risks/download/report`;

    const sub$ = this.http
      .get(fullUrl, {
        responseType: 'blob',
      })
      .pipe(
        map((data: any) => {
          const filename = 'risks.xlsx';
          FileSaver.saveAs(data, filename);
        })
      );
    return sub$;
  }

  private getReportConfig(reportName: string) {
    const config = {
      'maintenance-projects-completed-on-time': projectsCapitalCompleted,
      'projects-capital-completed': projectsCapitalCompleted,
      'assets-completed': assetsCompleted,
    };
    return config[reportName];
  }

  private getReportData(
    reportName: string,
    reportFilter: any
  ): Observable<any> {
    return this.http
      .post(`${this.apiUrl}/reporting/report-data`, {
        report: reportName,
        reportFilter,
      })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }
}

// Tech debt
export const projectsCapitalCompleted = [
  { name: 'projectID', description: '#' },
  { name: 'project Description', description: 'Description' },
  { name: 'project Engineer', description: 'Engineer' },
  { name: 'project Location', description: 'Location' },
  { name: 'project Sponsor', description: 'Sponsor' },
  { name: 'start Date', description: 'Start Date' },
];

export const assetsCompleted = [
  { name: 'name', description: 'Name' },
  { name: 'description', description: 'Description' },
  { name: 'expected Value When Complete', description: 'Complete Value(R)' },
  { name: 'project Name', description: 'Project Name' },
];
