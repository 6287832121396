import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { MessagesService } from './messages.service';
import { NotificationsService } from '@shared/services/notifications.service';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [MessagesService],
})
export class MessagesComponent implements OnInit {
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  public selectedTab = 1;
  public messages = [];
  public meetings: Array<any>;
  totalMessages: any;
  constructor(
    private messagesService: MessagesService,
    private notificationsService: NotificationsService
  ) {
    messagesService.getNewNotifications().subscribe(x => {
      this.messages = x.items
      this.totalMessages = x.totalItems;
    });
  }

  ngOnInit() {
    this.notificationsService.messageNotification$.subscribe((x) => {
      if (!x) {
        return;
      }
      this.messages.push({
        name: x.Subject,
        text: x.ShortMessage,
        time: new Date(),
      });
    });
  }

  openMessagesMenu() {
    this.trigger.openMenu();
    this.selectedTab = 0;
    this.updateMessage();
  }

  updateMessage() {
    let ids = this.messages.map(x => x.id)
    this.messagesService.markMessagesAsSeen(ids).subscribe(x => {

    })
  }
  onMouseLeave() {
    this.trigger.closeMenu();
  }

  stopClickPropagate(event: any) {
    event.stopPropagation();
    event.preventDefault();
  }
}
