import { Directive, ElementRef, Input, OnInit } from '@angular/core';

import { CurrentUserService } from '../../security/shared/current-user.service';

@Directive({
  selector: '[hideIfUnauthorized]'
})
export class HideIfUnauthorizedDirective implements OnInit {
  @Input()
  hideIfUnauthorized: string;
  constructor(
    private el: ElementRef,
    private currentUserService: CurrentUserService
  ) {}
  ngOnInit() {
    if (!this.currentUserService.hasPermission(this.hideIfUnauthorized)) {
      this.el.nativeElement.style.display = 'none';
    }
  }
}
