import { HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ConfirmDialogService } from '@core/confirm-dialog/confirm-dialog.service';
import { BaseHttpService } from '@core/services/base.service';
import { UserIdleService } from 'angular-user-idle';

import { CurrentUser } from './current-user.model';
import { CurrentUserService } from './current-user.service';
import { map, switchMap, concatMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

/**
 * Putting all our client authentication logic in a centralized
 * application-wide singleton AuthService will help us keep our code organized.
 */

@Injectable({
  providedIn: 'root',
})
export class AuthService extends BaseHttpService {
  public token: string;
  userIdle: UserIdleService;
  jwtHelper: JwtHelperService;
  router: Router;
  confirmDialogService: ConfirmDialogService;
  headers: HttpHeaders;
  loginServicePath = '/security/login/';
  currentUserService: CurrentUserService;
  hasCalledConfirmDialogService = false;

  constructor(public injector: Injector) {
    super(injector);

    this.userIdle = injector.get(UserIdleService);
    this.jwtHelper = injector.get(JwtHelperService);

    this.router = injector.get(Router);

    this.confirmDialogService = injector.get(ConfirmDialogService);

    this.currentUserService = injector.get(CurrentUserService);
  }

  loginOld(username: string, password: string): any {
    // const devicePrint = document.getElementById('devicePrint').innerHTML;
    // console.log(devicePrint);

    // const deviceTokenCookie = localStorage.getItem('deviceTokenCookie');

    const login$ = this.http
      .post(
        `${this.apiUrl}${this.loginServicePath}`,
        JSON.stringify({
          email: username,
          password,
        }),
        this.options
      )
      .pipe(
        map((res: any) => {
          if (res) {
            // this.setSession(res);
          }
          return res;
        })
      );

    return login$;
  }

  login(username: string, password: string): any {
    let headers = new HttpHeaders();
    headers = headers.set('Content-Type', 'application/x-www-form-urlencoded');
    const body = `username=${username}&password=${password}&grant_type=password`;

    const login$ = this.http
      .post(`${this.tokenUrl}`, body, { headers })
      .pipe(
        concatMap((res: any) => {
          return this.getUserDetails(res.access_token);
        })
      );

    return login$;
  }

  getUserDetails(token) {
    let headers = new HttpHeaders();
    // headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    headers = headers.set('Authorization', 'Bearer ' + token);

    return this.http.get(`${this.apiUrl}/users/profile`, { headers }).pipe(
      map((res: any) => {
        if (res) {
          this.setSession(res, token);
        }
        return { token };
      })
    );
  }

  setNewPassword(
    email: string,
    newPassword: string,
    verifyPassword: string,
    resetPasswordToken: string
  ): any {
    return this.http
      .post(
        `${this.apiUrl}/security/set-new-password`,
        JSON.stringify({
          email,
          Password: newPassword,
          verifyPassword,
          passwordToken: resetPasswordToken,
        }),
        this.options
      )
      .pipe(
        map((res: any) => {
          this.logout();
          return res;
        })
      );
  }

  
  setNewPasswordByAdmin(
    email: string,
    newPassword: string,
  ): any {
    return this.http
      .post(
        `${this.apiUrl}/security/set-new-password2`,
        JSON.stringify({
          email,
          Password: newPassword
        }),
        this.options
      )
      .pipe(
        map((res: any) => {
          return res;
        })
      );
  }


  resetPassword(username): any {
    return this.http
      .post(
        `${this.apiUrl}/security/reset-password`,
        JSON.stringify({ email: username }),
        this.options
      )
      .pipe(
        map((res: any) => {
          this.logout();
          return res;
        })
      );
  }

  public isAuthenticated(): boolean {
    const token = localStorage.getItem('id_token');
    // Check whether the token is expired and return
    // true or false
    return true; // bypassing login
    return !this.jwtHelper.isTokenExpired(token);
  }

  getCurrentUserId(): number {
    return JSON.parse(localStorage.getItem('currentUser'))
      ? JSON.parse(localStorage.getItem('currentUser'))['userId']
      : 0;
  }

  private setSession(authResult, token) {
    let currentUser = new CurrentUser();

    currentUser = {
      jobTitle: authResult.jobTitle,
      name: authResult.name,
      surname: authResult.surname,
      email: authResult.email,
      id: authResult.id,
      permissions: authResult.permissions,
      roles: authResult.roles,
    };

    this.currentUserService.currentUser = currentUser;

    localStorage.setItem('id_token', token);
    localStorage.setItem('currentUser', JSON.stringify(currentUser));
  }

  logout() {
    localStorage.removeItem('id_token');
    localStorage.removeItem('encryptToken');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('currentUser');
  }
}
