import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-dropdown-button',
  templateUrl: './dropdown-button.component.html',
  styleUrls: ['./dropdown-button.component.scss'],
})
export class DropdownButtonComponent implements OnInit {
  selectedValue: any;
  @Input() values = [];
  @Input() displayProperty;
  @Input() dropdownCaption = 'Select value';
  @Output() valueSelected: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}
  onValueSelected() {
    this.valueSelected.emit(this.selectedValue);
  }
}
