import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@shared/shared.module';
import { PdfViewerModule } from 'ng2-pdf-viewer';

import { DocumentViewerComponent } from './document-viewer.component';
import { DocumentViewerService } from './document-viewer.service';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule,
    CommonModule,
    FormsModule,
    PdfViewerModule,
  ],
  exports: [DocumentViewerComponent],
  declarations: [DocumentViewerComponent],
  providers: [DocumentViewerService],
  entryComponents: [DocumentViewerComponent],
})
export class DocumentViewerModule {}
