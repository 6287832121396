import 'jspdf-autotable';

import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import jsPDF from 'jspdf';
import { MatDialog } from '@angular/material/dialog';
import { DocumentViewerComponent } from '@core/document-viewer/document-viewer.component';

@Injectable({ providedIn: 'root' })
export class PrintService {
  printColumns: any[];
  data: any;
  constructor(private dialog: MatDialog) {}

  print(data, printColumns, header = 'Report', viewBeforePrinting = false) {
    this.data = data;
    this.printColumns = printColumns;
    this.setPrintColAttr();
    const doc = new jsPDF({
      orientation: 'landscape',
    });
    const pageCount = '{total_pages_count_string}';
    const officeCd = 'EIPM';
    const today = formatDate(new Date(), 'MM/dd/yyyy', 'en-US');
    doc.autoTable({
      columns: this.getPrintCols(),
      body: this.getPrintRows(),
      styles: {
        cellWidth: 'wrap',
        rowPageBreak: 'avoid',
        overflow: 'linebreak',
      },
      columnStyles: {
        description: { cellWidth: 'auto' },
        comments: { cellWidth: 'auto' },
        remarks: { cellWidth: 'auto' },
      },
      didDrawPage: (docData: any) => {
        // jsPDF 1.4+ uses getWidth, <1.4 uses .width
        const pageSize = doc.internal.pageSize;
        const pageWidth = pageSize.width ? pageSize.width : pageSize.getWidth();
        const pageHeight = pageSize.height
          ? pageSize.height
          : pageSize.getHeight();
        // Header
        doc.setFontSize(20);
        doc.setTextColor(40);
        doc.setFontStyle('normal');
        doc.text(header, pageWidth / 2, 10, 'center');

        // Footer
        const page = 'Page ' + doc.internal.getNumberOfPages();

        doc.setFontSize(10);
        doc.text(today, docData.settings.margin.left, pageHeight - 5);
        doc.text(officeCd, pageWidth / 2, pageHeight - 5, 'center');
        doc.text(
          page,
          pageWidth - docData.settings.margin.right - 10,
          pageHeight - 5
        );
      },
      margin: { top: 15, bottom: 15 },
    });
    if (viewBeforePrinting) {
      // doc.autoPrint({ variant: 'javascript' });
      // doc.save(`${header}-${today}.pdf`);
      this.viewDocument(doc.output('bloburl'), header);
      // window.open(doc.output('bloburl'), '_blank');
      // doc.output('dataurlnewwindow');
    } else {
      this.setiFrameForPrint(doc);
    }
  }
  private setPrintColAttr() {
    if (!this.printColumns[0]['header'] || !this.printColumns[0]['dataKey']) {
      this.printColumns.forEach((col) => {
        (col['header'] = col.description), (col['dataKey'] = col.name);
      });
    }
    const actionsIndex = this.printColumns.findIndex(
      (x) => x.name === 'actions'
    );
    if (actionsIndex >= 0) {
      this.printColumns.slice(actionsIndex);
    }
  }

  private setiFrameForPrint(doc) {
    const iframe = document.createElement('iframe');
    iframe.id = 'iprint';
    iframe.name = 'iprint';
    iframe.src = doc.output('bloburl');
    iframe.setAttribute('style', 'display: none;');
    document.body.appendChild(iframe);
    iframe.contentWindow.print();
  }

  private getPrintCols() {
    return this.printColumns;
  }

  private getPrintRows() {
    this.data.forEach((x) => {});
    return this.data;
  }

  private viewDocument(url, header) {
    const dialogRef = this.dialog.open(DocumentViewerComponent, {
      width: '1300px',
      height: '90%',
      disableClose: true,
    });

    dialogRef.componentInstance.dialogRef = dialogRef;

    dialogRef.componentInstance.documents = [
      {
        name: header,
        title: header,
        url: '',
        pdfViewerSRC: url,
        pdfBlob: null,
      },
    ];
  }
}
