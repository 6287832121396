import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { EIPMAppSettings } from '../../../eipm/eipm-app.settings';
import { Settings } from '../../../app.settings.model';
import { MenuService } from '../menu/menu.service';
import { CurrentUser } from 'src/app/security/shared/current-user.model';
import { CurrentUserService } from 'src/app/security/shared/current-user.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [MenuService],
})
export class SidenavComponent implements OnInit {
  public userImage = '../assets/img/users/user.jpg';
  public menuItems: Array<any>;
  public settings: Settings;
  currentUser: CurrentUser;
  constructor(
    public appSettings: EIPMAppSettings,
    public menuService: MenuService,
    private currentUserService: CurrentUserService
  ) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit() {
    this.menuItems = this.menuService.getVerticalMenuItems();
    this.currentUser = this.currentUserService.currentUser;
  }

  public closeSubMenus() {
    let menu = document.getElementById('vertical-menu');
    if (menu) {
      for (let i = 0; i < menu.children[0].children.length; i++) {
        let child = menu.children[0].children[i];
        if (child) {
          if (child.children[0].classList.contains('expanded')) {
            child.children[0].classList.remove('expanded');
            child.children[1].classList.remove('show');
          }
        }
      }
    }
  }
}
