export const verticalMenuItems = [
  {
    title: 'Home',
    icon: 'fa fa-home',
    routerLink: '/eipm/dashboard/main',
    active: true,
  },
  {
    title: 'Dashboards',
    icon: 'fa fa-tachometer',
    active: true,
    subMenu: [
      // {
      //   title: 'Dashboard',
      //   routerLink: '/eipm/dashboard/main',
      //   hideSmall: false,
      // },
      {
        hideSmall: false,
        title: 'Capital Projects',
        routerLink: '/eipm/dashboard/capital-projects',
      },
      {
        title: 'Maintenance',
        routerLink: '/eipm/dashboard/maintenance',
        hideSmall: false,
      },
      {
        title: 'SCM Processes',
        routerLink: '/eipm/dashboard/scm',
        hideSmall: false,
      },
      {
        title: 'Contract Management',
        hideSmall: false,
        routerLink: '/eipm/dashboard/contract-management',
      },
      {
        title: 'Assets',
        routerLink: '/eipm/dashboard/assets',
        hideSmall: false,
      },
    ],
  },
  {
    title: 'Projects',
    icon: 'fa fa-archive',
    permission: 'project-create.view',
    hideSmall: false,
    subMenu: [
      { title: 'Overview', routerLink: '/eipm/workspace', permission: '' },
      {
        title: 'Recent',
        permission: '',
        isRecentProjects: true,
        subMenu: [],
      },
      {
        title: 'Faults',
        permission: '',
        subMenu: [
          {
            title: 'Report a fault',
            routerLink: '/eipm/faults/report',
            permission: '',
            subMenu: [],
          },
          {
            title: 'Faults list',
            routerLink: '/eipm/faults',
            permission: '',
            subMenu: [],
          },
        ],
      },
      {
        title: 'Project Admin',
        icon: 'fa fa-archive',
        permission: 'project-create.view',
        hideSmall: true,
        subMenu: [
          {
            routerLink: '/eipm/projects/0/new-capital',
            title: 'Create Capital',
            permission: 'project-create.view',
            hideSmall: true,
          },
          {
            routerLink: '/eipm/projects/0/new-maintenance',
            title: 'Create Maintenance',
            permission: 'project-create.view',
            hideSmall: true,
          },
          {
            title: 'View Projects in Admin',
            routerLink: '/eipm/projects',
            permission: 'project-create.view',
          },
        ],
      },
      {
        title: 'Project Execution',
        icon: 'fa fa-puzzle-piece',
        subMenu: [
          {
            title: 'All Projects',
            routerLink: '/eipm/workspace/projects',
            permission: '',
          },
          {
            title: 'Milestones',
            routerLink: '/eipm/workspace/my-milestones',
            permission: 'milestones.view',
          },
          {
            title: 'Activities',
            routerLink: '/eipm/workspace/my-activities',
            permission: 'activities .view',
          },
          {
            title: 'Project Compliance',
            routerLink: '/eipm/workspace/compliances',
            permission: 'project-compliances.view',
            hideSmall: true,
          },
          {
            title: 'Open issue',
            routerLink: '/eipm/workspace/open-issues',
            permission: 'issues.view',
          },
          {
            title: 'All issues',
            routerLink: '/eipm/workspace/all-issues',
            permission: 'issues.view',
          },
          {
            title: 'Escalations',
            routerLink: '/eipm/workspace/escalations',
            permission: 'escalations.view',
          },
        ],
      },
    ],
  },
  {
    title: 'Supply Chain',
    icon: 'fa fa-truck',
    permission: 'scm-activities.view',
    hideSmall: true,
    subMenu: [
      {
        title: ' Project Supply Chain Activities',
        routerLink: '/eipm/workspace/supply-chain-activities',
        permission: 'scm-activities.view',
        hideSmall: true,
      },
      {
        title: 'SCM Evaluations',
        permission: '',
        subMenu: [
          {
            title: 'Tenders',
            routerLink: '/eipm/supply-chain/tenders',
            permission: '',
          },
          {
            title: 'Panel Acquisitions',
            routerLink: '/eipm/supply-chain/panels',
            permission: '',
          },
          {
            title: 'Quotations',
            routerLink: '/eipm/supply-chain/quotations',
            permission: '',
          },
          {
            title: 'Emergencies',
            routerLink: '/eipm/supply-chain/emergencies',
            permission: '',
          },
        ],
      },
    ],
  },
  {
    title: 'Assets',
    icon: 'fa fa-building',
    permission: 'assets.view',
    subMenu: [
      {
        title: 'Fixed Asset Register',
        routerLink: '/eipm/fixed-asset-register',
        permission: '',
      },
      {
        title: 'Project Assets',
        routerLink: '/eipm/workspace/assets',
        permission: 'assets.view',
      },
    ],
  },
  {
    title: 'Configurations',
    icon: 'fa fa-cog',
    permission: 'setup.view',
    hideSmall: true,
    subMenu: [
      {
        title: 'Contractor Duration Types',
        routerLink: '/eipm/setup/contractors-duration-types',
        permission: '',
      },
      {
        title: 'Standard Milestones',
        routerLink: '/eipm/setup/standard-milestones',
        permission: '',
      },
      {
        title: 'Contractors',
        routerLink: '/eipm/setup/contractors',
        permission: '',
      },
      {
        title: 'Maintenance Resolution Times',
        routerLink: '/eipm/setup/standard-resolution-times',
        permission: '',
      },
      {
        title: 'Departments',
        routerLink: '/eipm/setup/departments',
        permission: '',
      },
      {
        title: 'Users',
        routerLink: '/eipm/setup/users',
        permission: '',
      },
      {
        title: 'User Roles',
        routerLink: '/eipm/setup/user-roles',
        permission: '',
      },
      {
        title: 'Municipalities',
        routerLink: '/eipm/setup/municipalities',
        permission: '',
      },
    ],
  },
  {
    title: 'Notifications',
    icon: 'fa fa-bell',
    permission: '',
    hideSmall: true,
    subMenu: [
      {
        title: 'Email Notifications',
        routerLink: '/eipm/notifications',
        permission: '',
      },
      // {
      //   title: 'Calender',
      //   routerLink: '/eipm/schedule',
      //   permission: '',
      // },
    ],
  },
  {
    title: 'Reports',
    icon: 'fa fa-bar-chart-o',
    permission: 'reports.view',
    hideSmall: true,
    subMenu: [
      {
        title: 'All Reports',
        routerLink: '/eipm/reports',
        permission: '',
      },
      // {
      //   title: 'Quick Reports',
      //   permission: '',
      //   subMenu: [
      //     {
      //       title: 'Completed Capital Project',
      //       permission: '',
      //       clickEvent: 'report-projects-capital-completed',
      //     },
      //     {
      //       title: 'Assets Completed',
      //       permission: '',
      //       clickEvent: 'report-assets-completed',
      //     },
      //     {
      //       title: 'Maintenance Projects Completed on Time',
      //       permission: '',
      //       clickEvent: 'report-maintenance-projects-completed-on-time',
      //     },
      //   ],
      // },
      {
        title: 'Summary of all projects',
        routerLink: '/eipm/reports/summary-report',
        permission: '',
      },
    ],
  },
];
